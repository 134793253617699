import React from 'react'
import Layout from '../components/layout'
import ExperienceComp from '../components/Experience';

function Experience(props) {

    return (
        <Layout selected='experience'>
            <ExperienceComp/>
        </Layout>
    )
}

export default Experience