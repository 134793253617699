import React from 'react'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import nokiaLogo from '../assets/images/logos/nokia-logo.png'
import aShift from '../assets/images/logos/ashift-logo.png'
import canadaPost from '../assets/images/logos/canadaPost-logo.png'
import alcatelLogo from '../assets/images/logos/alcatel-logo.png'

function Experience(props) {

    return (
            <section id="one">
            <h2>Experience</h2>

            {/* Nokia - Software Developer */}
            <Paper style={{marginBottom: '60px'}}>
            <div className='PortfolioMain' style={{padding: '30px'}}>
            <img src={nokiaLogo} style={{ width: '10%' }} alt='Nokia'/>
            <div className='portfolioHeader'>
                <div>
                    <Typography variant="title" style={{display: 'inline-block'}} align='left'>
                        Nokia
                    </Typography>  
                    <Typography variant="subheading" style={{display: 'inline-block', float: 'right'}} align='right'>
                        Full-Stack Software Developer   
                    </Typography>
                </div>
                <div>
                    <Divider />   
                    <Typography component="p" style={{paddingTop: '20px', paddingBottom: '20px', fontStyle: 'italics'}}>
                        Carrier SDN software provider for service management, assurance, and management of the large-scale IP/MPLS networks
                    </Typography>
                </div>
            </div>
            <div className='portfolioDetails'>
                <Typography component="h3" variant="subheading" style={{opacity: 0.54, textTransform: 'uppercase'}}>
                    Develop Product
                </Typography>
                <div style={{color: 'black', fontSize: 'initial'}}>
                    <li>Working with a team in an agile environment on scalable enterprise web applications 
                        (using Java Spring, PostgreSQL, React, JavaScript, and CSS) 
                        used by major service providers for monitoring network equipment and provisioning new networks.</li>
                    <li>Developed GitHub integration for version control and shipping new updates of workflows</li>
                    <li>Led the development of a system for deploying custom customer setups</li>
                    <li>Implemented centralized logging using ELK stack</li>
                    <li>Created a Splunk dashboard and forwarded mined data for web application performance overview</li>
                    <li>Tools: Java Spring, Python, PostgreSQL // React, JS, jQuery // Sass, CSS // JSX</li>
                </div>
                {/* <Typography component="h3" variant="subheading" style={{paddingTop: '20px', opacity: 0.54}}>
                    ACCOMPLISHMENTS
                </Typography>
                <div style={{color: 'black', fontSize: 'initial'}}>
                    <li>Dramatically increased speed of UI development using atomic, scalable CSS</li>
                    <li>Built tool to convert HRIS data into user accounts to simplify onboarding</li>
                    <li>Implemented inline @tagging feature to increase app share-ability</li>
                </div> */}
                </div>
                </div>    
                <div className='portfolioFooter' style={{backgroundColor: 'rgba(0,0,0,0.02)'}}>
                <Typography component="h3" variant="subheading" style={{padding: '30px', paddingBottom: '20px', opacity: 0.54}}>
                    Jan 2017 - Present | Ottawa, ON
                </Typography> 
                </div>     
            </Paper>

            {/* aShift Pay - Co-founder/CTO */}
            <Paper style={{marginBottom: '60px'}}>
            <div className='PortfolioMain' style={{padding: '30px'}}>
            <img src={aShift} style={{ width: '10%' }} alt='Nokia'/>
            <div className='portfolioHeader'>
                <div>
                    <Typography variant="title" style={{display: 'inline-block'}} align='left'>
                        aShift Pay
                    </Typography>  
                    <Typography variant="subheading" style={{display: 'inline-block', float: 'right'}} align='right'>
                        Co-founder/CTO
                    </Typography>
                </div>
                <div>
                    <Divider />   
                    <Typography component="p" style={{paddingTop: '20px', paddingBottom: '20px', fontStyle: 'italics'}}>
                        FinTech company focused on financial solutions for mid-sized/micro financial companies
                    </Typography>
                </div>
            </div>
            <div className='portfolioDetails'>
                <Typography component="h3" variant="subheading" style={{opacity: 0.54, textTransform: 'uppercase'}}>
                    Develop Product
                </Typography>
                <div style={{color: 'black', fontSize: 'initial'}}>
                    <li>Responsible for designing the architecture of a FinTech product which involves constant research into various new technologies like GraphQL and cloud solutions like AWS EC2</li>
                    <li>Working on a team developing features within the product using React, React Native, Node.js and AWS Dynamodb (NoSQL)</li>
                    <li>Work with the marketing and product management team on developing approaches to the market and dealing with customers</li>
                    <li>Tools: Node.js, AWS, GraphQL, Dynamodb // React, JS // Sass, CSS // JSX</li>
                </div>
                {/* <Typography component="h3" variant="subheading" style={{paddingTop: '20px', opacity: 0.54}}>
                    ACCOMPLISHMENTS
                </Typography>
                <div style={{color: 'black', fontSize: 'initial'}}>
                    <li>Dramatically increased speed of UI development using atomic, scalable CSS</li>
                    <li>Built tool to convert HRIS data into user accounts to simplify onboarding</li>
                    <li>Implemented inline @tagging feature to increase app share-ability</li>
                </div> */}
                </div>
                </div>    
                <div className='portfolioFooter' style={{backgroundColor: 'rgba(0,0,0,0.02)'}}>
                <Typography component="h3" variant="subheading" style={{padding: '30px', paddingBottom: '20px', opacity: 0.54}}>
                    April 2018 - Present | Ottawa, ON
                </Typography> 
                </div>     
            </Paper>

            {/* Nokia - Software Test Engineer (Co-Op) */}
            <Paper style={{marginBottom: '60px'}}>
            <div className='PortfolioMain' style={{padding: '30px'}}>
            <img src={alcatelLogo} style={{ width: '10%' }} alt='Nokia'/>
            <div className='portfolioHeader'>
                <div>
                    <Typography variant="title" style={{display: 'inline-block'}} align='left'>
                        Alcatel-Lucent
                    </Typography>  
                    <Typography variant="subheading" style={{display: 'inline-block', float: 'right'}} align='right'>
                        Software Test Engineer (Co-Op)
                    </Typography>
                </div>
                <div>
                    <Divider />   
                    <Typography component="p" style={{paddingTop: '20px', paddingBottom: '20px', fontStyle: 'italics'}}>
                        Global telecommunications equipment company focused on fixed, mobile and converged networking hardware, IP technologies, software and services
                    </Typography>
                </div>
            </div>
            <div className='portfolioDetails'>
                <Typography component="h3" variant="subheading" style={{opacity: 0.54, textTransform: 'uppercase'}}>
                    Develop Product
                </Typography>
                <div style={{color: 'black', fontSize: 'initial'}}>
                    <li>Tested and created various test plans with a lot of corner cases to test the functionality of products across multiple sprints</li>
                    <li>Maintained regression and performance test beds, including publishing results and developing additional functionality where I reduced test errors by over 80% by working with software designers</li>
                    <li>Worked with software designers to fix bugs found on different product releases</li>
                </div>
                {/* <Typography component="h3" variant="subheading" style={{paddingTop: '20px', opacity: 0.54}}>
                    ACCOMPLISHMENTS
                </Typography>
                <div style={{color: 'black', fontSize: 'initial'}}>
                    <li>Dramatically increased speed of UI development using atomic, scalable CSS</li>
                    <li>Built tool to convert HRIS data into user accounts to simplify onboarding</li>
                    <li>Implemented inline @tagging feature to increase app share-ability</li>
                </div> */}
                </div>
                </div>    
                <div className='portfolioFooter' style={{backgroundColor: 'rgba(0,0,0,0.02)'}}>
                <Typography component="h3" variant="subheading" style={{padding: '30px', paddingBottom: '20px', opacity: 0.54}}>
                    Jan 2016 - August 2016 | Ottawa, ON
                </Typography> 
                </div>     
            </Paper>

            {/* Canada - Maintenance Planning and Performance - Maximo Support */}
            <Paper style={{marginBottom: '60px'}}>
            <div className='PortfolioMain' style={{padding: '30px'}}>
            <img src={canadaPost} style={{ width: '10%' }} alt='Nokia'/>
            <div className='portfolioHeader'>
                <div>
                    <Typography variant="title" style={{display: 'inline-block'}} align='left'>
                        Canada Post
                    </Typography>  
                    <Typography variant="subheading" style={{display: 'inline-block', float: 'right'}} align='right'>
                    Maintenance Planning and Performance - Maximo Support
                    </Typography>
                </div>
                <div>
                    <Divider />   
                    <Typography component="p" style={{paddingTop: '20px', paddingBottom: '20px', fontStyle: 'italics'}}>
                        Primary postal service operator in Canada
                    </Typography>
                </div>
            </div>
            <div className='portfolioDetails'>
                <Typography component="h3" variant="subheading" style={{opacity: 0.54, textTransform: 'uppercase'}}>
                    Develop Product
                </Typography>
                <div style={{color: 'black', fontSize: 'initial'}}>
                    <li>Developed ideas on how to improve DM (Direct Marketing) for Canada Post with technology (NFC and Mobile Application Integration). Chosen as one of the best ideas and made a presentation to the President and CEO of Canada Post as well as many Senior Vice Presidents and Vice Presidents</li>
                    <li>Managed database of assets across Canada using IBM Maximo</li>
                </div>
                {/* <Typography component="h3" variant="subheading" style={{paddingTop: '20px', opacity: 0.54}}>
                    ACCOMPLISHMENTS
                </Typography>
                <div style={{color: 'black', fontSize: 'initial'}}>
                    <li>Dramatically increased speed of UI development using atomic, scalable CSS</li>
                    <li>Built tool to convert HRIS data into user accounts to simplify onboarding</li>
                    <li>Implemented inline @tagging feature to increase app share-ability</li>
                </div> */}
                </div>
                </div>    
                <div className='portfolioFooter' style={{backgroundColor: 'rgba(0,0,0,0.02)'}}>
                <Typography component="h3" variant="subheading" style={{padding: '30px', paddingBottom: '20px', opacity: 0.54}}>
                    May 2015 - August 2015 | Ottawa, ON
                </Typography> 
                </div>     
            </Paper>

            </section>
    )
}

export default Experience